<template>
  <div class="section sign-list-model-Y sign-manage">
    <complex
      ref="complex"
      :class="{'tab-show': tabShow}"
      :offset-top="58"
      :data="myList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      @pullingUp="onPullingUp"
      @pullingDown="onPullingDown"
      @diffChange="diffChange"
    >
      <template slot="header">
        <div class="complex-header">
          <new-search
            v-model="requestData.custCn"
            search-type="custName"
            placeholder="搜索客户名称"
            @getLists="searchAction"
          />
        </div>
      </template>
      <template slot="sticky-ele">
        <new-search
          v-if="scrollDiff >= 1"
          v-model="requestData.custCn"
          search-type="custName"
          placeholder="搜索客户名称"
          @getLists="searchAction"
        />
        <condition-info
          v-model="conditionValue"
          :tabs="tabList"
          :filter-num="filterNum"
          :fast-list="fastList"
          :is-tab-show="scrollDiff < 1"
          @tabChange="tabChange"
          @fastChange="fastChange"
          @toFilter="toFilter"
        />
      </template>
      <template slot="content">
        <div class="content">
          <div class="sign-list">
            <div
              class="list-wrapper"
            >
              <error-page
                v-if="!myList.length"
              />
              <div
                v-for="(item, index) of myList"
                :key="index"
                class="sign-list-ul"
              >
                <div
                  v-if="pickTimeType === 0"
                  class="sign-list-title"
                >
                  <div class="sign-title-monthbox">
                    <span class="sign-title-month">{{ item.monthValue }}</span>
                    <i class="sign-title-unit">月{{ item.yearValue ? `/${item.yearValue}` : '' }}</i>
                  </div>
                  <div
                    class="sign-title-summary"
                  >
                    <template v-for="(value, n) in statusHander(item.statusCount)">
                      <span
                        :key="n"
                        :class="{'sign-title-line': n > 0}"
                      >{{ `${value.registerStatusText + value.count}次` }}</span>
                    </template>
                  </div>
                </div>
                <div class="sign-list-content">
                  <div
                    v-for="(itemA, indexA) of item.monthList"
                    :key="indexA"
                    class="sign-list-li"
                    @click="toDetail(itemA.registerId)"
                  >
                    <div class="sign-list-li-A">
                      <div class="sign-list-li-left">
                        <div class="sign-list-lileft-title">
                          <span class="sign-list-lileft-title-span">{{ itemA.custCn }}</span>
                          <div
                            v-if="itemA.registerStatus !== '1'"
                            class="sign-list-lileft-title-state"
                          >
                            <span
                              :class="itemA.registerStatus === '0' ? 'sign-list-lileft-title-abnormal' : 'sign-list-lileft-title-normal'"
                            >{{ itemA.registerStatusText }}</span>
                          </div>
                        </div>
                        <div class="sign-list-lileft-checkAddr">{{ itemA.checkAddr }}</div>
                      </div>
                      <div class="sign-list-li-right">
                        <div class="sign-list-lileft-date">
                          {{ itemA.noYear }}
                        </div>
                        <div class="sign-list-lileft-time">
                          {{ itemA.time }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if=" permissionsrType === 1"
                      class="sign-list-li-C"
                    >
                      <iconpark-icon
                        name="yonghu"
                        class="iconpark-class"
                      ></iconpark-icon>
                      <span>{{ itemA.createName }}</span> |
                      <span>{{ `${itemA.branchOrgTitle}${ itemA.branchOrgTitle ? '-' + (itemA.orgTitle || '') : (itemA.orgTitle || '')}` }}</span>
                    </div>
                    <div
                      v-if="permissionsrType === 0"
                      class="sign-list-li-B"
                      @click.stop="handleFollow(itemA)"
                    >
                      <div class="sign-list-li-follow">查看跟进信息</div>
                      <div class="sign-list-li-jump">
                        <span
                          v-if="!itemA.trackId"
                          class="sign-list-li-jump-text"
                        >未填写</span>
                        <iconpark-icon
                          name="jiantou"
                          class="arrowsd"
                        ></iconpark-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </complex>
    <filter-model
      :show.sync="filterModelShow"
      :data.sync="filterModelData"
      :num.sync="filterNum"
      :nodes="modelConfig"
      :dict="dictList"
      @confirm="filterConfirm"
    />

    <!-- 底部 -->
    <sign-tab v-model="tabShow" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import dayjs from 'dayjs';
import complex from '_c/complex'
import conditionInfo from '_c/condition/filter.vue'
import errorPage from '_c/errorPage';
import FilterModel from '_c/fliter-model'
import newSearch from '_c/search/search-model';

import { getCustRegisterList } from '@/api/sign';
import { findValueByKey } from '../../lib/until';
import signTab from './components/sign-tab';
import { branchConfig, mineConfig } from './filterConfig'

export default {
  name: 'SignManage',
  components: {
    complex,
    conditionInfo,
    newSearch,
    signTab,
    errorPage,
    FilterModel
  },
  data() {
    return {
      tableData: [[], []],
      tabList: [],
      myList: [],
      postList: [],
      requestData: {
        custCn: '',
        branchOrgIdList: [],
        registerTimeStart: '',
        registerTimeEnd: '',
        registerStatus: '',
        pageNum: 1,
        pageSize: 10
      },
      filterData: {
        filterIndex: {
          name: '我的',
          value: 0,
          options: []
        },
        registerStatus: {
          name: '全部',
          value: '',
          options: []
        },
      },
      // 保存权限aid
      aid: [],
      detailAid: [],
      // 数据时间集合分类
      listSet: {},
      // 数据，去重
      listId: [],
      statusCounts: [],
      // timeComponentsBool: false,
      filterTime: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
      listType: 0, // 列表类型
      pickValue: {}, // 组件 日期数据
      pickTimeType: 0, // 时间类型
      permissionsrType: 0, // 权限类型  我的签到/本部门及以下
      conditionValue: {
        tabItem: { code: 0 },
        fastItem: { code: '' }
      },
      scrollDiff: 0,
      tabShow: true,
      filterModelShow: false,
      filterModelData: {},
      filterNum: 0,
      // 在路由撤退时用来触发子组件数据更新
      activatedNum: 0
    }
  },
  computed: {
    ...mapGetters('sign', ['filter', 'aidList']),
    ...mapGetters(['dictList']),
    loadEnable() {
      return this.myList && !!this.myList.length;
    },
    tabItem() {
      return this.conditionValue.tabItem || {}
    },
    fastItem() {
      return this.conditionValue.fastItem || {}
    },
    fastList() {
      let list = []
      if (Array.isArray(this.dictList.registerStatus)) {
        list = this.dictList.registerStatus.map(item => ({ ...item, label: item.text, code: item.value }))
      }
      return list
    },
    modelConfig() {
      let config = []
      if (this.isMineTab(this.tabItem.code)) {
        config = mineConfig
      } else if (this.isBranchTab(this.tabItem.code)) {
        config = branchConfig
      }
      return config
    }
  },
  provide() {
    return {
      manageContainer: this
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    this.activatedNum++
    // this.fromFilter();
  },
  methods: {
    ...mapMutations(['CLEAR_FILTER', 'SET_KEEPALIVE']),
    assignTime(value, timeIndex) {
      // this.pickValue = value
      this.pickTimeType = Number(timeIndex);
      this.requestData.pageNum = 1
      this.requestData.registerTimeStart = value.startTime
      this.requestData.registerTimeEnd = value.endTime
      // if (JSON.stringify(value) === '{}') {
      //   this.requestData.registerTimeStart = ''
      //   this.requestData.registerTimeEnd = ''
      //   return
      // }
      // if (timeIndex === 0) {
      //   this.filterTime = value.month;
      //   const end_time = this.getNextMonthDate(Number((value.monthValue[0])), Number(value.monthValue[1])).end_time;
      //   const dateTime = new Date(end_time).setDate(new Date(end_time).getDate() - 1);
      //   const newDateTime = dayjs(dateTime).format('YYYY-MM-DD');
      //   this.requestData.registerTimeStart = this.getNextMonthDate(Number((value.monthValue[0])), Number(value.monthValue[1])).star_time;
      //   this.requestData.registerTimeEnd = newDateTime;
      // } else {
      //   this.filterTime = `${value[0]} ~ ${value[1]}`;
      //   this.requestData.registerTimeStart = value[0];
      //   this.requestData.registerTimeEnd = value[1];
      // }
    },
    // 计算下个月的开始日期
    getNextMonthDate(year, month) {
      let star_time = ''
      let end_time = ''
      let next_month = ''
      const cur_month = month < 10 ? '0' + month : month
      star_time = year + '-' + cur_month + '-01'
      if (month === 12) {
        end_time = Number(year) + 1 + '-01' + '-01'
      } else {
        next_month = parseInt(month + 1) < 10 ? '0' + parseInt(month + 1) : parseInt(month + 1)
        end_time = year + '-' + next_month + '-01'
      }
      return {
        star_time,
        end_time
      }
    },
    init() {
      this.getGetters();
      this.tabList = [];
      this.aidList.forEach((item, i) => {
        this.tabList.push({ label: item.mlabel, value: i, code: i, aidList: item.actions, num: '' });
        // 列表Tab切换
        this.filterData.filterIndex.options.push({ content: `${item.mlabel}`, class: 'cube-foos', value: i });
        item.actions.forEach(itemA => {
          switch (itemA.acode) {
            case 'query':
              this.aid.push(itemA.aid);
              break;
            case 'detail':
              this.detailAid.push(itemA.aid);
              break;
          }
        });
      });
      this.filterData.filterIndex.name = this.aidList[0].mlabel;
      this.permissionsrType = this.aidList[0].mlabel === '本部门及以下' ? 1 : 0;
      this.getLists(this.requestData, this.aid[0]);
    },
    // 搜索栏搜索
    searchAction(val = '') {
      this.requestData.pageNum = 1;
      this.refreshLoad(val)
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    refreshLoad(val, type) {
      const index = this.aid.length > 1 ? this.permissionsrType : 0;
      const custCn = this.requestData.custCn || val;
      this.getLists(
        { ...this.requestData, custCn },
        this.aid[index],
        type
      );
    },
    // 列表操作
    getLists(data, aid) {
      if (!this.myList.length) this.$loading.show();
      if (this.requestData.pageNum === 1) {
        this.listSet = {}
        this.listId = []
      }
      getCustRegisterList(data, aid).then(res => {
        this.$loading.hide();
        if (res.flag) {
          if (res.data.pageInfo) {
            const list = res.data.pageInfo.list || [];
            if (list.length > 0) {
              const curYear = new Date().getFullYear()
              list.forEach(item => {
                item.createTime = typeof item.createTime === 'string' ? item.createTime : ''
                const time = item.createTime.substr(0, 7)
                if (!(time in this.listSet)) {
                  const year = +time.substr(0, 4)
                  this.listSet[time] = {
                    yearValue: year < curYear ? year : '',
                    monthValue: +time.substr(5, 2),
                    statusCount: {},
                    monthList: []
                  }
                }
                if (this.listId.includes(item.registerId)) return
                // 数据处理
                item.registerStatusText = findValueByKey(this.dictList.registerStatus, +item.registerStatus);
                item.date = item.createTime.split(' ')[0];
                item.noYear = item.date.substring(5, item.date.length);
                item.time = item.createTime.split(' ')[1];
                item.orgTitle = item.orgTitle || '';
                this.listId.push(item.registerId)
                this.listSet[time].statusCount[item.registerStatus] = (this.listSet[time].statusCount[item.registerStatus] || 0) + 1
                this.listSet[time].monthList.push(item)
              })
              this.myList = Object.keys(this.listSet).sort((a, b) => Number(b.replace('-', '')) - Number(a.replace('-', ''))).map(key => this.listSet[key])
              if (this.$refs.complex) this.$refs.complex.upload = true;
            } else {
              if (this.requestData.pageNum === 1) this.myList = []
              if (this.$refs.complex) this.$refs.complex.upload = false;
              this.$refs.complex.onPullingUp();
            }
          } else {
            if (this.$refs.complex) this.$refs.complex.upload = false;
            this.$refs.complex.onPullingUp();
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      })
    },
    getMonth(date) {
      return new Date(date).getMonth() + 1;
    },
    // 切换tab
    switchPermissionsrType(val) {
      this.permissionsrType = Number(val);
      this.filterModelData = {}
      // this.CLEAR_FILTER('sign');
      this.requestData.custCn = '';
      this.requestData.registerTimeStart = '';
      this.requestData.registerTimeEnd = '';
      this.requestData.branchOrgIdList = [];
      this.requestData.registerStatus = '';
      // this.filterTime = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
      this.listType = Number(val);

      this.searchAction();
    },
    toFilter() {
      this.filterModelShow = true
      // this.SET_KEEPALIVE([`${this.$route.name}`]);
      // const index = this.permissionsrType + '';
      // this.$router.push(
      //   {
      //     name: 'SignFilter',
      //     params: {
      //       i: index
      //     }
      //   }
      // );
    },
    fromFilter() {
      this.getGetters();
      const index = this.aid.length > 1 ? this.permissionsrType : 0;
      this.getLists(this.requestData, this.aid[index]);
    },
    // 获取vuex数据
    getGetters() {
      // for (const i in this.filter) {
      //   if (i === 'branchOrgIdList') {
      //     this.requestData[i] = this.filter[i].value || [];
      //   } else if (i === 'filterTime') {
      //     this.assignTime(this.filter[i].value.data, this.filter[i].value.type)
      //   } else {
      //     this.requestData[i] = this.filter[i].value || this.filter[i].value === 0 ? this.filter[i].value : '';
      //   }
      // }
    },
    // 详情
    toDetail(id) {
      const detailAid = this.detailAid.length === 1 ? this.detailAid[0] : this.detailAid[this.permissionsrType];
      this.SET_KEEPALIVE([`${this.$route.name}`]);
      this.$router.push({
        name: 'sign-details',
        params: {
          id: id,
          type: this.permissionsrType,
          aid: detailAid
        }
      });
    },
    // 跳转新增跟进
    handleFollow(item) {
      this.SET_KEEPALIVE([`${this.$route.name}`]);
      if (item.trackId) {
        this.$router.push({ name: 'follow-details', params: { followId: item.trackId }});
      } else {
        this.$router.push({
          name: 'AddeditFollow',
          query: {
            custId: item.custId,
            custCn: item.custCn,
            trackTime: item.createTime,
            signId: item.registerId,
            from: 'fromSign'
          }
        });
      }
    },
    tabChange() {
      this.conditionValue.fastItem = { code: '' }
      this.requestData.registerStatus = ''
      this.switchPermissionsrType(this.tabItem.code)
    },
    fastChange() {
      this.requestData.registerStatus = this.fastItem.code
      this.requestData.pageNum = 1
      this.fromFilter()
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    // 下拉刷新
    onPullingDown() {
      this.requestData.pageNum = 1
      this.refreshLoad('', 'refresh')
    },
    // 上拉加载
    onPullingUp() {
      this.requestData.pageNum++
      this.refreshLoad('', 'upload')
    },
    diffChange(diff) {
      if (diff > 1) {
        this.scrollDiff = 1
      } else {
        this.scrollDiff = diff
      }
    },
    statusHander(statusCount) {
      const registerStatus = (Array.isArray(this.dictList.registerStatus) && this.dictList.registerStatus) || []
      const list = []
      registerStatus.forEach(item => {
        if (item.value !== 1 && statusCount[item.value]) {
          list.push({
            ...item,
            count: statusCount[item.value] || 0,
            registerStatusText: item.text
          })
        }
      })
      return list
    },
    isMineTab(code) {
      const item = this.tabList[code]
      return item && (item.label.includes('我的'))
    },
    isBranchTab(code) {
      const item = this.tabList[code]
      return item && (item.label.includes('部门'))
    },
    filterConfirm() {
      this.setRequestData()
      this.requestData.pageNum = 1;
      this.requestData.pageSize = 10;
      this.fromFilter()
      this.filterModelShow = false
    },
    setRequestData() {
      const value = {}
      const timeType = this.filterModelData['timeType-noValue'] || '0'
      if (timeType === '0') {
        value.startTime = (this.filterModelData.timeDate && `${this.filterModelData.timeDate}-01`) || ''
        value.endTime = (this.filterModelData.timeDate && `${this.filterModelData.timeDate}-${dayjs(this.filterModelData.timeDate).daysInMonth()}`) || ''
      } else {
        value.startTime = this.filterModelData.timeRange[0] || ''
        value.endTime = this.filterModelData.timeRange[1] || ''
      }
      this.assignTime(value, timeType)
      if ('branchOrgIdList' in this.filterModelData) {
        this.requestData.branchOrgIdList = this.filterModelData.branchOrgIdList
      }
      // for (const key in this.filterModelData) {
      //   if (['oppoLevel', 'trackReliable'].includes(key)) {
      //     this.requestData[key] = `${this.filterModelData[key]}`
      //   } else {
      //     this.requestData[key] = this.filterModelData[key]
      //   }
      // }
    }
  }
}

</script>

<style lang="scss" src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.complex-header {
  width:100%;
  height: 58px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: none;
}
.list-wrapper{
  .out-box:first-child{
    margin-top:10px;
    .card-box{
      padding-top:19px ;
    }
  }
  .out-box:last-child{
    .card-box{
      padding-bottom:24px;
    }
  }
}

.errorHeight{
  height:calc(100vh - 118px);
}
.filterBar{
  display: flex;
  justify-content: space-between;
  height: 28px;
  padding: 10px 15px;
  background:$color-FFF;
  .filterBar-left{
    display: flex;
    justify-content: space-between;
  }
  .filterBar-right{
    display: flex;
    justify-content: space-between;
  }
  .filterBar-frame{
    display: flex;
    align-items: center;
    padding: 6px 6px 4px 6px;
    font-size: 12px;
    border-radius: 4px;
  }
  .filterBar-icon{
    margin-left: 2px;
    font-size: 12px;
  }
  .theme{
    color: #00A4FF;
    background: #E8FBFF;
    span{
      font-weight: 600;
    }
  }
  .other{
    color: #23252E;
    font-weight: 400;
  }
}

// 列表
.sign-list{
  height: calc(100% - 58px - 48px - 52px);
  overflow: hidden;
  transform: rotate(0deg); // fix 子元素超出边框圆角部分不隐藏的问题
}
.sign-list-ul{
  .sign-list-title{
    display: flex;
    justify-content: space-between;
    height: 52px;
    margin: 0 15px;
    line-height: 52px;
    background-color: $color-F7;
    .sign-title-monthbox{
      .sign-title-month{
        color: #23252E;
        font-weight: 600;
        font-size: 24px;
      }
      .sign-title-unit{
        margin-left: 4px;
        color: #23252E;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

      }
    }
    .sign-title-summary{
      display: flex;
      color: #999BA3;
      font-size: 12px;
      span {
        position: relative;
      }
      .sign-title-line {
        margin-left: 13px;
      }
      .sign-title-line::before {
        position: absolute;
        top: 50%;
        left: -7px;
        width: 1px;
        height: 14px;
        background: #D9D9D9;
        transform: translateY(-50%);
        content: " ";
      }
    }
  }
}
.sign-list-content{
  background: $color-FFF;
  .sign-list-li{
    height: 68px;
    margin: 0 15px;
    padding: 16px 0;
    background: $color-FFF;
    border-bottom: 1px solid $color-F7;
    .sign-list-li-A{
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .sign-list-li-left{
        flex:1;
        text-align: left;
        .sign-list-lileft-title{
          display: flex;
          justify-content: flex-start;
          margin-bottom: 4px;
          .sign-list-lileft-title-span{
            // flex: 1;
            max-width: 240px;
            color: #23252E;
            font-size: 16px;
            line-height: 20px;
            @extend .uniline-ellipsis;
          }
          .sign-list-lileft-title-state{
            width: 40px;
            margin-left: 4px;
            .sign-list-lileft-title-normal{
              padding: 0 2px;
              color: #5A5C66;
              font-size: 11px;
              line-height: 16px;
              background: #F7F7F7;
              border-radius: 2px;
            }
            .sign-list-lileft-title-abnormal{
              padding: 0 2px;
              color: #FF585C;
              font-size: 11px;
              line-height: 16px;
              background: #FFF2F0;
              border-radius: 2px;
            }
          }
        }
        .sign-list-lileft-checkAddr{
          color: #999BA3;
          font-size: 12px;
          line-height: 16px;
           @extend .uniline-ellipsis;
        }
      }
      .sign-list-li-right{
        width: 60px;
        text-align: right;
        .sign-list-lileft-date{
          margin-bottom: 4px;
          color: #23252E;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
        .sign-list-lileft-time{
          color: #999BA3;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .sign-list-li-B{
      display: flex;
      justify-content: space-between;
      .sign-list-li-follow{
        color: #23252E;
        font-size: 12px;
        line-height: 16px;
      }
      .sign-list-li-jump{
        .sign-list-li-jump-text{
          color: #FF7B00;
          font-size: 12px;
          line-height: 16px;

        }
      }
      .arrowsd {
        color: #999BA3;
        font-size: 10px;
        vertical-align: middle;
      }
    }
    .sign-list-li-C{
      color: #5A5C66;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      .iconpark-class{
        margin-right: 4px;
        vertical-align: middle;
      }
    }
  }
}
.sign-manage {
  /deep/.sticky-view-complex.tab-show {
    height: calc(100% - 70px);
  }
}
</style>
