export const mineConfig = [
  {
    field: 'timeType-noValue',
    title: '时间类型',
    type: 'simple',
    defaultVal: '0',
    componentName: 'TagSelect',
    options: [{ text: '按月', value: '0' }, { text: '按日', value: '1' }]
  },
  {
    field: 'timeDate',
    title: '按月选择',
    type: 'date',
    dateType: 'year-month',
    defaultVal: '',
    componentName: 'SingleDate',
    dateFormat: 'YYYY-MM',
    hidden(data) {
      if (data['timeType-noValue'] === undefined || data['timeType-noValue'] === '0') {
        return false
      }
      return true
    }
  },
  {
    field: 'timeRange',
    title: '按日选择',
    type: 'date',
    defaultVal: [],
    componentName: 'RangeDate',
    dateFormat: 'YYYY-MM-DD',
    maxRange: '',
    hidden(data) {
      if (data['timeType-noValue'] === '1') {
        return false
      }
      return true
    }
  },
]

export const branchConfig = [
  {
    field: 'branchOrgIdList',
    title: '分公司',
    type: 'Array',
    defaultVal: [],
    componentName: 'OldCompBranch'
  },
  {
    field: 'timeType-noValue',
    title: '时间类型',
    type: 'simple',
    defaultVal: '0',
    componentName: 'TagSelect',
    options: [{ text: '按月', value: '0' }, { text: '按日', value: '1' }]
  },
  {
    field: 'timeDate',
    title: '按月选择',
    type: 'date',
    dateType: 'year-month',
    defaultVal: '',
    componentName: 'SingleDate',
    dateFormat: 'YYYY-MM',
    hidden(data) {
      if (data['timeType-noValue'] === undefined || data['timeType-noValue'] === '0') {
        return false
      }
      return true
    }
  },
  {
    field: 'timeRange',
    title: '按日选择',
    type: 'date',
    defaultVal: [],
    componentName: 'RangeDate',
    dateFormat: 'YYYY-MM-DD',
    maxRange: '',
    hidden(data) {
      if (data['timeType-noValue'] === '1') {
        return false
      }
      return true
    }
  },
]

